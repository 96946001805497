import "./style.css"

import React from 'react'

export const About = () => {
    return (
        <div className="about">
            <h1>About</h1>
            <div className="body">
                developments will follow, surely
            </div>
        </div>
    )
}

export default About
