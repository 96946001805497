import "./style.css"

import React from 'react'

export const Projects = () => {
    return (
        <div className="projects">
            <h1>Projects</h1>
            <div className="body">
                projects will be put here, really    
             </div>
        </div>
    )
}

export default Projects
